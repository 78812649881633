<template>
  <div class="page-allgame">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span v-if="type === 'partBidding'">参与竞价</span>
      <span v-if="type === 'pushBidding'">发布竞价</span>
      <span v-if="type === 'buy' || type === 'recovery' || type === 'medium'">全部游戏</span>
      <span v-if="type === 'sell'">平台帮卖</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" v-if="type === 'sell'" />
      <span v-if="type === 'sell'">选择游戏</span>
      <span v-if="type === 'pay'">专业鉴定</span>
      <span v-if="type === 'free'">免费鉴定</span>
      <img
        src="@/assets/images/icon-right-grey.png"
        alt=""
        v-if="type === 'pay' || type === 'free'"
      />
      <span v-if="type === 'pay' || type === 'free'">选择游戏</span>
    </div>
    <div class="buy-game-list">
      <div class="tabs">
        <div
          class="tabs-item-box"
          v-for="(item, index) in tabs"
          :key="index"
          @click="changeIndex(index)"
        >
          <p :class="tabCurrent == index ? 'tabs-font' : ''">{{ item }}</p>
          <img src="@/assets/images/line.png" alt="" v-if="tabCurrent == index" />
        </div>
      </div>
      <div class="ptfilter">
        <p
          v-for="(item, index) in listValue"
          :key="index"
          :class="valueCurrent == index ? 'selected-letter' : ''"
          @click="selectChangeIndex(item, index)"
        >
          {{ item }}
        </p>
      </div>
      <div class="selectAllGame-content-gamediv">
        <div
          class="selectAllGame-content-gamediv-item"
          v-for="item in list"
          :key="item.id"
          @click="goodsItem(item)"
        >
          <div class="selectAllGame-gamediv-img">
            <img :src="item.image" alt="" class="logo" />
            <img src="@/assets/images/mobile-games.png" alt="" v-if="item.type === 0" />
          </div>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <identifyPopup
      :cost="cost"
      :dialogVisible="dialogVisible"
      :goodId="goodId"
      @place="clickEven"
    />
    <Login ref="loginModal" />
  </div>
</template>
<script setup>
import Login from '@/components/Login/index.vue'
import identifyPopup from './components/identifyPopup.vue'
import { ref, reactive, onMounted } from 'vue'
import { gameList } from '@/api/home'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getChatService } from '@/api/service'
import { ElMessage } from 'element-plus'
const tabs = ref(['全部游戏', '手游', '端游'])
const router = useRouter()
const route = useRoute()
const store = useStore()
const tabCurrent = ref(0)
const loginModal = ref(null)
const listValue = ref([
  '全部',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
])
const valueCurrent = ref(0)
const page = reactive({
  pageNum: 24,
  pageSize: 1
})
const params = ref({
  type: -1,
  page: page.pageSize,
  page_size: page.pageNum
})
const list = ref([])
const type = ref('')
const cost = ref('')
const dialogVisible = ref(false)
const goodId = ref('')
const clickEven = (val) => {
  dialogVisible.value = val
}
function changeIndex(index) {
  tabCurrent.value = index
  params.value.type = index - 1
  getList()
}
function selectChangeIndex(val, index) {
  valueCurrent.value = index
  if (val !== '全部') {
    params.value.initial = val
  } else {
    if (params.value.initial) {
      delete params.value.initial
    }
  }
  getList()
}
function getList() {
  gameList(params.value).then((res) => {
    list.value = res.data.list
  })
}
function goodsItem(item) {
  if (type.value === 'buy') {
    router.push({ path: '/wares', query: { id: item.id, gameName: item.name, type: 'buy' } })
  } else if (type.value === 'sell' || type.value === 'pushBidding') {
    store.commit('sell/setGame', item)
    if (localStorage.getItem('token')) {
      getChatService({ type: 4, game_id: item.id }).then((res) => {
        if (res.code == 0) {
          let to = router.resolve({
            path: '/service',
            query: { account: res.data.id }
          })
          window.open(to.href, '_blank')
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else {
      loginModal.value.visible = true
      loginModal.value.loginVisible = true
    }
    // router.push({ path: '/selectRegion', query: { type: type.value } })
  } else if (type.value === 'pay') {
    goodId.value = item.id
    dialogVisible.value = true
    store.commit('sell/setGame', item)
  } else if (type.value === 'free') {
    router.push({ path: '/appraisalForm', query: { type: type.value } })
    store.commit('sell/setGame', item)
  } else if (type.value === 'partBidding') {
    store.commit('sell/setGame', item)
    router.push({
      path: '/wares',
      query: { id: item.id, gameName: item.name, type: 'partBidding' }
    })
  } else if (type.value === 'recovery') {
    router.push({ path: '/retrieve', query: { type: 'recovery' } })
  } else if (type.value === 'medium') {
    router.push({ path: '/retrieve', query: { type: 'medium' } })
  }
}
onMounted(() => {
  type.value = route.query.type
  if (route.query.cost) {
    cost.value = route.query.cost
  }
  getList()
})
</script>
<style lang="less">
.ant-modal-content {
  border-radius: 20px;
  .ant-modal-body {
    padding: 0;
  }
}
.page-allgame {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .breadcrumb {
    margin: 14px 0;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    img {
      margin: 0 3px;
    }
    span:last-child {
      color: #333333;
    }
  }
  .buy-game-list {
    padding: 30px;
    background: #ffffff;
    border-radius: 12px;
    .tabs {
      display: flex;
      margin-bottom: 20px;
      .tabs-item-box {
        width: 80px;
        text-align: center;
        cursor: pointer;
        p {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 28px;
        }
        .tabs-font {
          color: #333333;
          font-size: 20px;
        }
      }
    }
    .ptfilter {
      width: 1140px;
      height: 36px;
      line-height: 36px;
      background: #f5f5f5;
      margin: 0 auto;
      display: flex;
      cursor: pointer;
      padding: 0 20px;
      p {
        width: 162px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #969696;
        text-align: center;
      }
      .selected-letter {
        color: #ffffff;
        background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
        border-radius: 30%;
      }
    }
    .selectAllGame-content-gamediv {
      width: 1140px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .selectAllGame-content-gamediv-item {
        width: 188px;
        text-align: center;
        margin-top: 40px;
        cursor: pointer;
        .selectAllGame-gamediv-img {
          position: relative;
          .logo {
            width: 80px;
            height: 80px;
            position: relative;
          }
          img:nth-child(2) {
            position: absolute;
            right: 55px;
          }
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-top: 12px;
        }
      }
      .selectAllGame-content-gamediv-item:not(:first-child) {
        //margin-left: 69px;
      }
    }
  }
}
</style>
