<template>
  <div>
    <el-dialog v-model="props.dialogVisible" title="专业鉴定" width="30%" @close="close">
      <div class="content" v-html="contentHtml"></div>
      <template #footer>
        <span class="dialog-footer">
          <p @click="serviceClick">联系客服</p>
          <p @click="place">￥{{ props.cost }}立即鉴定</p>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import { placeOrder } from '@/api/order'
import { useRouter } from 'vue-router'
import { article } from '@/api/goods'
import { getChatService } from '@/api/service'
import { ElMessage } from 'element-plus'
const router = useRouter()
const props = defineProps({
  cost: String,
  dialogVisible: Boolean,
  goodId: String
})
const serviceClick = () => {
  if (localStorage.getItem('token')) {
    getChatService({ type: 2, game_id: props.goodId }).then((res) => {
      if (res.code == 0) {
        let to = router.resolve({ path: '/service', query: { account: res.data.id } })
        window.open(to.href, '_blank')
      }
    })
  } else {
    ElMessage.warning('请先进行登录')
  }
}
const contentHtml = ref('')
const getArticle = () => {
  article({ id: 13 }).then((res) => {
    if (res.code == 200) {
      contentHtml.value = res.data.pc_content
    }
  })
}
getArticle()
const emit = defineEmits(['update:dialogVisible'])
const place = () => {
  let params = {
    type: 3,
    goods_id: props.goodId
  }
  placeOrder(params).then((res) => {
    if (res.code == 200) {
      localStorage.setItem('goods_id', res.data.goods_id)
      router.push({
        path: '/identifyPay',
        query: { price: res.data.amount / 100, orderNo: res.data.order_no }
      })
    } else {
      emit('place', false)
    }
  })
}
const close = () => {
  emit('place', false)
}
</script>
<style lang="less" scoped>
:deep(.el-dialog) {
  background: #f5f6fa;
  border-radius: 20px;
}
:deep(.el-dialog__header) {
  background: #ffffff !important;
  margin-right: 0;
  border-radius: 20px 20px 0 0;
  padding: 0;
  height: 72px;
  line-height: 72px;
}
:deep(.el-dialog__title) {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-left: 20px;
}
.content {
  height: 495px;
}
:deep(.el-dialog__footer) {
  padding: 0;
  height: 109px;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 20px 20px;
  text-align: center;
}
.dialog-footer {
  height: 109px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    width: 198px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #06a4f9;
    border-radius: 30px;
    cursor: pointer;
  }
  p:nth-child(1) {
    background: #eff8ff;
  }
  p:nth-child(2) {
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    color: #ffffff;
    margin-left: 30px;
  }
}
</style>
